import Vue from 'vue'

// 图片裂图
Vue.prototype.$imageErrorUser = require('@/assets/images/headImg.jpg') // 用户头像默认图
Vue.prototype.$imageErrorLogo = require('@/assets/images/seller/default-store.png') // 店铺LOGO默认图
Vue.prototype.$imageErrorGoods = require('@/assets/images/seller/default-goods.png') // 商品默认图
Vue.prototype.$imageErrorLive = require('@/assets/images/seller/default-goods.png') // 直播默认图
Vue.prototype.$imageErrorAvatar = require('@/assets/images/liveplatform/default-avatar.png') // 中控台用户头像

// 链接
const domain = process.env.VUE_APP_BASE_URL
Vue.prototype.$testBaseURL = domain // 主站域名
Vue.prototype.$workBasePath = window.location.protocol + "//work." + domain // 卖家中心
Vue.prototype.$itemBasePath = window.location.protocol + "//item." + domain // 商品展示
Vue.prototype.$liveBasePath = window.location.protocol + "//live." + domain // 直播门户页
Vue.prototype.$liveplatformBasePath = window.location.protocol + "//liveplatform." + domain // 直播中控台
Vue.prototype.$loginBasePath = window.location.protocol + "//login." + domain // 全局登录页
Vue.prototype.$sycmBasePath = window.location.protocol + "//sycm." + domain // 生意参谋

Vue.prototype.$campaignBasePath = window.location.protocol + "//campaign." + domain // 营销活动

// 酷特官网
Vue.prototype.$uulianBase = ""

// 云平台登录
Vue.prototype.$uutalentBase = process.env.VUE_APP_BASE_UUTALENT

// 直播推流端工具下载地址
Vue.prototype.$aliyunclientSetup =
	"https://dtapp-pub.dingtalk.com/dingtalk-desktop/aliyun/aliyunclient_setup_v1.5.0.exe?spm=a2c4g.11186623.0.0.1d391af1ohNsQA&file=aliyunclient_setup_v1.5.0.exe"
