const sycm = {
	header: {
		logo: '生意参谋',
		shopkeeper: '店主',
		help: '帮助中心',
		logout: '退出'
	},
	footer: {
		text1: 'Copyright © 2019-现在 安徽酷特信息科技有限公司 Co.Ltd. All rights reserved.',
		text2: '闽ICP备14008589号-1',
		text3: '增值电信业务经营许可证[闽B2-20160039]',
		text4: '闽公网安备 35021102002111号'
	},
	home: {
		overview: '实时概况',
		tradeRank: '行业排名',
		wirelessMix: '无线占比',
		yesterdayAllDay: '昨日全天',
		rank: '排名',
		refundPanel: '退款看板',
		refundRate: '退款率',
		refundPrice: '成功退款金额',
		refundNumber: '成功退款笔数',
		dayBefore: '较前一日',
		weekBefore: '较上周日期'
	},
	ipoll: {
		overview: '实时总览',
		refreshTips: '点此快速更新数据面板',
		mobileMix: '移动端占比',
		trend: '实时趋势',
		goodsRank: '商品榜',
		meanwhile: '较昨日同时段',
		toPay: '实时催付宝'
	},
	flow: {
		overview: '实时总览',
		visitorStore: '访问店铺',
		visitorGoods: '访问商品',
		goodsVisitors: '商品访客数',
		conversion: '转化',
		perCapitaViews: '人均浏览量',
		oldVisitors: '老访客数',
		newVisitors: '新访客数',
		followStores: '关注店铺人数',
		liveVisitors: '直播间访客数',
		videoVisitors: '短视频访客数',
		graphicVisitors: '图文访客数',
		storePageVisitors: '店铺页访客数',
		periodScatter: '时段分布',
		periodScatter1: '时段分布解读',
		periodScatter2: '近7天日均访客数最多的时间段为：',
		periodScatter3: '找准访客高峰时段，果断上新呀！注意兼顾PC端和无线端访客访问习惯哦。看看',
		behaviorScatter: '行为分布',
		sourceKeywords: '来源关键词',
		viewsScatter: '浏览量分布',
		buyersPlaced: '下单买家数'
	},
	cate: {
		goodsRank: '商品排行',
		standard: '标准类目',
		standardAll: '全部标准类目',
		category: '选择类目',
		contrast: '选择对比',
		target: '选择指标',
		goodsSales: '商品动销',
		collectAddBuy: '收藏加购',
		goodsVisit: '商品访问',
		indicatorMonitor: '核心指标监控',
		goodsCollectAddBuy: '商品收藏加购',
		goodsAddBuy: '商品加购件数',
		goodsVisitors: '商品访客数',
		goodsAddBuyPer: '商品加购人数',
		goodsCollectPer: '商品收藏人数',
		visitPlusRate: '访问加购转化率',
		accessCollectRate: '访问收藏转化率'
	},
	trade: {
		terminalForm: '终端构成',
		cateForm: '类目构成',
		overview: '交易总览',
		perTicketSales: '客单价',
		visit: '访客',
		placeOrder: '下单',
		orderPayConversion: '下单-支付转化率',
		payment: '支付'
	},
	lives: {
		overview: '直播全局表现',
		orderMoney: '直播种草成交金额',
		storeOrderMoney: '店播种草成交金额',
		storeGoodsDeal: '本店商品直播成交',
		meanValue: '同行同层均值',
		shopGoodsDeal: '本店商品直播间成交',
		shopGoodsTimes: '本店商品分场次效果'
	},
	common: {
		updateTime: '更新时间',
		statisticalTime: '统计时间',
		payMoney: '支付金额',
		payGoods: '支付商品数',
		rmb: '元',
		visitor: '访客数',
		payBuyers: '支付买家数',
		views: '浏览量',
		paySubOrder: '支付子订单数',
		today: '今日',
		contrastDay: '对比日',
		yesterday: '昨日',
		addBuys: '加购件数',
		payConversion: '支付转化率',
		sourceScatter: '地域分布',
		total: '共',
		trend: '趋势',
		plantOrderMoney: '种草成交金额',
		plantOrderRate: '店铺种草成交占比',
		liveStoreNewCustomer: '直播间店铺新客人数'
	},
	unit: {
		day: '天',
		order: '单'
	},
	popover: {
		formula: '关联因素公式',
		title: '参谋长解答',
		range: '统计范围',
		repeat: '去重逻辑',
		wave: '波动差异',
		define: '分端定义',
		defineSubOrder: '子订单定义',
		refundRate1: '指成功退款笔数（即成功退款子订单数）/支付子订单数。退款包括售中和售后的仅退款和退货退款，且只包含全额成功退款不包括部分退款。',
		refundRate2: '如果退款率过高',
		refundRate3: '表示商品退款订单在增加。建议关注买家退款原因，做后续的优化行动。',
		refundRate4: '如果退款率过低',
		refundRate5: '表示商品退款订单在减少。建议继续保持，关注同行优秀商家表现。',
		visitor1: '统计周期内买家访问您店铺的去重人数。',
		visitor2: '统计范围统计周期内访问宝贝详情页、店铺页（含店铺首页、大促活动页等)、观看店铺自播直播间、观看自制全屏页短视频3秒及以上、浏览店铺自制图文3秒及以上、浏览商品微详情3秒及以上的去重人数。',
		visitor3: '去重逻辑一个人在统计时间范围内访问多次只记为一个。所有终端访客数为PC端访客数和无线端访客数相加去重',
		visitor4: '波动差异实时计算过程中，店铺流量高峰时，可能会出现交易数据处理快于浏览数据，导致访客数小于支付买家数。同时当天实时数据可能会出现不稳定的情况，和次日看到的昨日数据存在差距，请以次日的数据结果为准。',
		views1: '统计周期内买家访问您店铺的次数。',
		views2: '0点截至当前时间观看店铺自播直播间、观看自制全屏页短视频3秒及以上、浏览店铺自制图文3秒及以上、浏览商品微详情3秒及以上、访问宝贝详情页及店铺其他页面的被访问的次数。',
		views3: '一个人在统计时间内访问多次记为多次。所有终端的浏览量等于PC端浏览量和无线端浏览量之和。',
		payMoney1: '买家拍下后通过支付宝支付给您的金额。',
		payMoney2: '未剔除事后退款金额（退款包括售中和售后的仅退款和退货退款），预售阶段付款（预售开始截至当日，被支付了定金的订单总金额）在付清当天才计入内。所有终端的支付金额为PC端支付金额和无线端支付金额之和。',
		payMoney3: '未剔除事后退款金额，预售阶段付款在付清当天才计入内，货到付款订单确认收货时计入内。所有终端的支付金额为PC端支付金额和无线端支付金额之和。',
		payMoney4: '支付渠道不论是电脑上还是手机上，拍下为电脑上，就将后续的支付金额计入PC端；拍下为手机或Pad上，就将后续的支付金额计入无线端。',
		paySubOrder1: '统计时间内的支付子订单数，支付子订单数也被称为支付笔数。',
		paySubOrder2: '比如某个买家在某个店铺购买了多个宝贝一起下单支付，订单后台会展现每个产品每个SKU粒度下会有一条记录，这个就是一个子订单。',
		addBuys1: '统计周期内，商品加购的件数之和',
		payConversion1: '统计时间内，支付买家数/访客数的值，即来访客户转化为支付买家的比例。',
		payConversion2: '如果支付转化率过高',
		payConversion3: '支付转化率上升表示您店铺的访客中成交的比例提升。建议继续保持并关注同行优秀商家表现。',
		payConversion4: '如果支付转化率过低',
		payConversion5: '支付转化率下滑表示您店铺的访客中成交的比例下降。建议关注对支付转化率的影响较大的商品详情页面的承接情况以及关注下单催付的工具设置。',
		toPay1: '成为“实时催付买家”的条件： 1.在本店下单且未支付 2.未在其他店铺购买同类宝贝 3.每天最多提供潜力指数TOP50的买家',
		liveVisitors1: '进入店铺自播直播间的去重人数',
		liveVisitors2: '统计周期内观看店铺自播直播间的去重人数，一个人在统计时间范围内访问多次只记为一个',
		videoVisitors1: '统计周期内观看自制短视频（全屏页）3秒及以上的去重人数，一个人在统计时间范围内访问多次只记为一个',
		graphicVisitors1: '统计周期内浏览店铺自制图文3秒及以上的去重人数，一个人在统计时间范围内访问多次只记为一个',
		storePageVisitors1: '统计周期内访问您店铺首页、活动页等店铺页面的去重人数，一个人在统计时间范围内访问多次只记为一个',
		sourceKeywords1: '访客通过搜索这些关键词后访问店铺内的页面，计算下单买家，归属于最近的搜索并下单商品的关键词。',
		viewsScatter1: '访客在店内的浏览量分布。',
		visitPlusRate1: '统计时间内商品加购人数/商品访客数，即访问用户转化为加购用户的比例',
		accessCollectRate1: '收藏人数/访客数',
		orderPayConversion1: '统计时间内下单且支付买家数/下单买家数，注意统计时间内下单且支付买家数小于等于统计时间内支付买家数哦！',
		liveOrderMoney1: '所选时间范围内，通过点击直播间商品后15天内引导支付成功的总金额',
		liveOrderMoney2: '此处为店播直播间种草成交金额之和',
		storeOrderMoney1: '通过店铺自播直播间引导支付成功的总金额',
		storeOrderMoney2: '所选时间范围内，通过点击店铺自播直播间商品后15天内引导支付成功的总金额',
		liveDealNumber1: '通过直播种草成交的用户数',
		liveDealNumber2: '统计周期内，通过点击直播间商品后15天内引导支付成功的用户数',
		liveDealMoney1: '通过直播引导成交的金额',
		liveDealMoney2: '统计周期内，点击直播间下挂的本店商品的用户15天内引导支付成功的商品金额之和',
		plantOrderMoney1: '所选时间范围内，通过点击直播间商品后15天内引导支付成功的商品金额（含预告和回放）（仅统计本店商品）',
		plantOrderRate1: '所选时间范围内，种草成交金额/本店铺成交金额（仅统计本店商品）',
		liveStoreNewCustomer1: '所选时间范围内，本店商品直播种草成交人数中为店铺新客的人数（店铺新客：统计时间内支付一次且365天内首次支付的用户）',
		livePlantGrass: '相同一级类目相同层级，层级划分基于近30日商家成交金额计算，与生意参谋划分一致'
	},
	dropdown: {
		terminal: [{
			value: 0,
			label: '所有终端'
		}, {
			value: 1,
			label: '无线端'
		}, {
			value: 2,
			label: 'PC端'
		}]
	},
	tableHead: {
		rank: '排行',
		goodsName: '商品名称',
		actions: '操作',
		region: '地域',
		buyersInfo: '买家信息',
		potentialOrder: '潜力订单',
		latestOrderTime: '最近订单时间',
		orderStatus: '订单状态',
		orderBaby: '下单宝贝',
		orderMoney: '下单金额',
		orderTime: '下单时间',
		orderId: '下单ID',
		orderConversion: '下单转化率',
		keywords: '关键词',
		proportion: '占比',
		goods: '商品',
		payNumber: '支付件数',
		goodsAddBuy: '商品加购件数',
		goodsVisitors: '商品访客数',
		terminal: '终端',
		cate: '类目',
		crowd: '人群',
		liveVisitors: '直播间访客人数',
		dealNumber: '种草成交人数',
		dealMoney: '种草成交金额',
		goodsCollectRate: '商品收藏转化率',
		goodsViews: '商品浏览量',
		countTime: '统计日期',
		liveNewFans: '直播间新增粉丝数',
		plantDealPeople: '种草成交人数',
		plantDealNumber: '种草成交件数',
		plantDealCount: '种草成交笔数',
		goodsClickPeople: '商品点击人数',
		goodsClickNumber: '商品点击次数',
		goodsPlusPeople: '商品加购人数',
		goodsPlusNumber: '商品加购件数',
		goodsInfo: '商品主图/标题',
		goodsId: '商品ID',
		userName: '账号名称',
		liveTitle: '直播标题',
		timesId: '场次ID',
		broadcastTime: '开播时间'
	},
	actions: {
		realTime: '实时',
		realTimeLive: '实时直播',
		refresh: '刷新',
		prevPage: '上一页',
		nextPage: '下一页',
		orderInfo: '订单详情',
		day: '日',
		week: '周',
		month: '月',
		download: '下载',
		babyUpperLower: '宝贝上下架时间',
		reset: '重置',
		viewTrends: '查看趋势'
	},
	placeholder: {
		date: '选择日期',
		week: '选择周',
		month: '选择月',
		searchGoods: '请输入商品名称、ID'
	},
	options: {
		ipollSource: [
			'访客数排行TOP10',
			'支付买家数排行TOP10'
		],
		orderStatus: {
			0: '已取消',
			11: '买家待付款',
			20: '商家待发货',
			30: '商家已发货',
			40: '已完成'
		},
		terminal: {
			0: '手机订单',
			1: 'PC订单'
		},
		flowPanel: {
			1: '访客数',
			2: '浏览量',
			3: '人均浏览量',
			4: '老访客数',
			5: '新访客数',
			6: '关注店铺人数',
			7: '直播间访客数',
			8: '短视频访客数',
			9: '图文访客数',
			10: '店铺页访客数'
		},
		flowSource: [
			'访客数占比排行TOP10',
			'下单买家数占比排行TOP10'
		],
		flowEarlier: {
			1: '较前日同时段',
			2: '较前1日',
			3: '较前7日',
			4: '较前30日',
			5: '较前1日',
			6: '较上周',
			7: '较上月'
		},
		flowChart: {
			1: '30天前',
			2: '12周前',
			3: '12月前'
		},
		goodsSales: [{
			label: '支付金额',
			value: 'payment_amount'
		}, {
			label: '支付件数',
			value: 'payment_quantity'
		}, {
			label: '支付买家数',
			value: 'payment_buyers'
		}, {
			label: '支付转化率',
			value: 'payment_rate'
		}],
		collectAddBuy: [{
			label: '商品加购件数',
			value: 'goods_into_carts'
		}, {
			label: '商品加购人数',
			value: 'goods_into_carts_persons'
		}, {
			label: '商品收藏人数',
			value: 'goods_collects'
		}, {
			label: '访问加购转化率',
			value: 'views_into_cart_rate'
		}, {
			label: '访问收藏转化率',
			value: 'views_collect_rate'
		}],
		goodsVisit: [{
			label: '商品访客数',
			value: 'goods_visitors'
		}, {
			label: '商品浏览量',
			value: 'goods_views'
		}],
		catePanel: {
			1: '商品加购件数',
			2: '商品加购人数',
			3: '商品收藏人数',
			4: '访问加购转化率',
			5: '访问收藏转化率'
		},
		cateEarlier: {
			1: '较昨日同时段',
			2: '较前7日',
			3: '较前30日',
			4: '较上周同期',
			5: '较上周',
			6: '较上月'
		},
		marketSwiper: [{
			label: '种草成交金额',
			value: 'price',
			tip: '种草成交金额: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的商品金额（含预告和回放）（仅统计本店商品）'
		}, {
			label: '店铺种草成交占比',
			value: 'trans_rate',
			tip: '店铺种草成交占比: 所选时间范围内，种草成交金额/本店铺成交金额（仅统计本店商品）'
		}, {
			label: '直播间店铺新客人数',
			value: 'new_persons',
			tip: '直播间店铺新客人数: 所选时间范围内，本店商品直播种草成交人数中为店铺新客的人数（店铺新客：统计时间内支付一次且365天内首次支付的用户）'
		}, {
			label: '直播间新增粉丝数',
			value: 'new_fans',
			tip: '直播间新增粉丝数: 所选时间范围内，用户在直播间内的新增关注粉丝数'
		}, {
			label: '种草成交人数',
			value: 'trans_persons',
			tip: '种草成交人数: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的人数（含预告和回放）（仅统计本店商品）'
		}, {
			label: '种草成交件数',
			value: 'trans_num',
			tip: '种草成交件数: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的件数（含预告和回放）（仅统计本店商品）'
		}, {
			label: '种草成交笔数',
			value: 'order_num',
			tip: '种草成交笔数: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的订单数（含预告和回放）（仅统计本店商品）'
		}, {
			label: '商品点击人数',
			value: 'goods_visitors',
			tip: '商品点击人数: 所选时间范围内，用户点击宝贝进入详情页的人数（仅统计本店商品）'
		}, {
			label: '商品点击次数',
			value: 'goods_views',
			tip: '商品点击次数: 所选时间范围内，用户点击宝贝进入详情页的次数（仅统计本店商品）'
		}, {
			label: '商品加购人数',
			value: 'goods_into_cart_persons',
			tip: ''
		}, {
			label: '商品加购件数',
			value: 'goods_into_cart_num',
			tip: ''
		}],
		livePlantGrass: [{
			label: '我的直播间',
			value: '1',
			tip: '本店商品在本店自播直播间的成交表现'
		// }, {
		// 	label: '合作直播间',
		// 	value: '2',
		// 	tip: '本店商品在其他合作主播直播间的成交表现'
		}]
	},
	errorMsg: {
		maxColumn: '最多选择5个指标',
		minColumn: '最少选择1个指标'
	},
	empty: {
		rank: '数据量过小，暂不支持分析'
	}
}
export default sycm
