// 直播门户

const liveRouter = [{
	path: "/",
	component: () => import('@/views/live/index'),
	meta: {
		title: '酷特直播'
	}
}, {
	path: "/upload",
	component: () => import('@/views/live/upload'),
	meta: {
		title: '酷特直播|下载客户端'
	}
}, {
	path: '*',
	redirect: '/'
}]

export default liveRouter
