<template>
	<el-scrollbar class="sycm-body">
		<sycm-header />
		<div class="sycm-wrap">
			<div class="sycm-menu" v-if="menuList && menuList.children.length > 1">
				<div class="menu-top">
					<svg-icon :icon-class="menuList.meta.icon"></svg-icon>
					<p class="top-name">{{ menuList.meta.subtitle || menuList.meta.title }}</p>
				</div>
				<ul class="menu-list">
					<li v-for="(item, index) in menuList.children" :key="index"
						:class="{ active: item.path == $route.path }">
						<router-link :to="item.path">{{ item.meta.title }}</router-link>
					</li>
				</ul>
			</div>
			<div class="sycm-main">
				<router-view :key="key" />
			</div>
		</div>
		<sycm-footer />
	</el-scrollbar>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import sycmRouter from '@/router/modules/sycm'
import sycmHeader from './components/sycm/header'
import sycmFooter from './components/sycm/footer'
import SvgIcon from '../components/SvgIcon/index.vue'
export default {
	components: { sycmHeader, sycmFooter, SvgIcon },
	computed: {
		key() {
			return this.$route.path
		}
	},
	data() {
		return {
			menuList: null
		}
	},
	created() {
		this.getMenuList()
	},
	methods: {
		getMenuList() {
			const route = this.$route
			const { matched } = route
			const routes = this.generateRoutes(sycmRouter)
			routes.forEach(item => {
				if (matched[0].path === item.path) {
					this.menuList = item
				}
			})
		},
		generateRoutes(routes, basePath = '/') {
			const res = []
			for (let route of routes) {
				if (route.hidden) { continue }
				const data = {
					path: this.resolvePath(basePath, route.path),
					meta: route.meta
				}
				if (route.children) {
					data.children = this.generateRoutes(route.children, data.path)
				}
				res.push(data)
			}
			return res
		},
		resolvePath(basePath, routePath) {
			if (isExternal(routePath)) {
				return routePath
			}
			if (isExternal(basePath)) {
				return basePath
			}
			return path.resolve(basePath, routePath)
		},
		isExternal(path) {
			return isExternal(path)
		}
	}
}
</script>
<style lang="scss">
.el-popper {
	.popover-text {
		font-size: 12px;
		line-height: 1.5;
	}
	&.sycm-popover {
		padding: 0;
		max-width: 477px;
		min-width: 437px;

		.popover-head {
			color: #fdfeff;
			background: $--sycm-popover-background;
			padding: 10px 10px 8px 36px;
			font-size: 12px;
			line-height: 1.5;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			font-weight: 600;
		}

		.popover-main {
			padding: 10px 16px;
			min-height: 68px;
			font-size: 12px;
			line-height: 1.5;
			box-sizing: border-box;

			.popover-formula {
				.formula-title {
					font-weight: 600;
					margin-bottom: 10px;
				}

				.formula-item {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 80px;

					.item-block {
						text-align: center;
						margin-left: 10px;

						.fit {
							height: 1px;
							background-color: #606266;
							margin: 5px 0;
						}
					}
				}
			}

			.popover-refund {
				.refund-item {
					margin-bottom: 15px;

					.item-title {
						font-weight: 600;
						margin-bottom: 6px;

						&.red {
							color: #fe7c24;
						}

						&.green {
							color: #00ba26;
						}

						[class^=el-icon-] {
							margin-right: 6px;
							font-weight: bold;
						}
					}
				}
			}
			.inversion-item {
				font-size: 12px;
				line-height: 1.5;
				margin-bottom: 15px;
				.item-name {
					font-weight: 600;
					margin-bottom: 6px;

					&.red {
						color: #fe7c24;
					}

					&.green {
						color: #00ba26;
					}

					[class^=el-icon-] {
						margin-right: 6px;
						font-weight: bold;
					}
				}
				.item-box {
					color: #666666;
				}
			}
			
			.item {
				display: flex;
				margin-bottom: 6px;
				.item-label {
					color: #333333;
					width: 62px;
					flex-shrink: 0;
					font-weight: bold;
				}
				.item-block {
					width: 0;
					flex: 1;
					color: #666666;
				}
			}
		}

		.popper__arrow {
			border-bottom-color: $--sycm-popover-arrow;

			&:after {
				border-bottom-color: $--sycm-popover-arrow;
			}
		}
	}
	&.sycm-popover-cate {
		padding: 0;
	}
}
.sycm-bar-dropdown {
	height: 28px;
	line-height: 28px;
	.el-dropdown-link {
		cursor: pointer;
		display: inline-block;
		font-size: 12px;
	}
}
</style>
<style lang="scss" scoped>
::v-deep a {
	color: inherit;
}

.sycm-body {
	height: 100%;
	background-color: #eef1f6;

	::v-deep .el-scrollbar__wrap {
		.el-scrollbar__view {
			min-width: 1240px;
		}
	}

	.sycm-wrap {
		display: flex;
		width: 1210px;
		margin: 10px auto 100px;
		min-height: calc(100vh - 258px);

		.sycm-menu {
			flex-shrink: 0;
			width: 160px;
			margin-right: 10px;
			box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
			background-color: #ffffff;

			.menu-top {
				padding: 20px 0;
				line-height: 32px;
				text-align: center;
				color: $--sycm-hover-1;
				border-bottom: 1px solid #eee;

				.svg-icon {
					font-size: 32px;
					margin-bottom: 6x;
				}

				.top-name {
					font-size: 16px;
				}
			}

			.menu-list {
				li {
					height: 44px;
					line-height: 44px;
					font-size: 14px;
					border-left: 3px solid transparent;

					a {
						width: 100%;
						padding-left: 47px;
						display: inline-block;
						box-sizing: border-box;
					}

					&:hover {
						background-color: rgba($--sycm-border, .1);
					}

					&.active {
						color: $--sycm-primary;
						border-left-color: $--sycm-border;
						background-color: rgba($--sycm-border, .1);
					}
				}
			}
		}

		.sycm-main {
			flex: 1;
			width: 0;
		}
	}
}

@media (min-width: 1600px) {
	.sycm-body {
		::v-deep .el-scrollbar__wrap {
			.el-scrollbar__view {
				min-width: 1440px;
			}
		}

		.sycm-wrap {
			width: 1430px;
		}
	}
}
</style>