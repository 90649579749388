const teacher = {
	base: {
		student: '学生',
		account: '账号',
		seller: '卖家中心',
		accountInfo: '账号信息',
		shopInfo: '店铺信息',
		logout: '退出当前账号',
		liveplatform: '直播中控台',
		realName: '姓名',
		liveScore: '直播评分',
		shopScore: '网店评分',
		marketScore: '推广评分'
	},
	order: {
		paneTitle1: '订单信息',
		paneTitle2: '收货人信息',
		paneTitle3: '发货信息',
		paneTitle4: '商品信息'
	},
	class: {
		cateName: '行业类目',
		basicFunds: '系统原始基础资金',
		lastAdjustFunds: '上次调整后资金',
		adjustFunds: '本次需调整资金',
		cateTips: '请选择需要还原的行业类目，可多选'
	},
	member: {
		sno: '学号',
		regtime: '注册时间'
	},
	score: {
		tips1: '得分说明',
		tips2: '1、{student}的成绩值由系统评分和{teacher}评分之和组成；',
		tips3: '2、系统评分值最高为79分，{teacher}评分值最高为21分，总和为100分。',
		tips4: '点击评分',
		ranking: '总分排行',
		systemRank: '智能评分排名',
		teacherRank: '教师评分排名',
		adminRank: '管理员评分排名',
		studentName: '学生名',
		shopName: '店铺名',
		account: '账号'
	},
	live: {
		tips: '注：“ * ” 为必填项'
	},
	tips: {
		imageSize1: '图片大小(1920像素 * 410像素)'
	},
	market: {
		goodsBaseInfo: '商品基础信息',
		enrollBaseInfo: '活动报名基础信息',
		allSku: '全部规格(sku)'
	},
	creative: {
		modalityTips: '表示创意的类型及尺寸。',
		sourceTips: '创意制作的来源。',
	},
	formItem: {
		shop: '店铺',
		shopkeeper: '店主',
		shopName: '店铺名称',
		shopId: '店铺ID',
		shopStatus: '店铺状态',
		shopOwnerCard: '店主身份证号',
		class: '班级',
		belongClass: '所属班级',
		belongCate: '所属类别',
		addresses: '联系地址',
		cellPhone: '手机号码',
		telephone: '联系电话',
		phone: '电话号码',
		ownerCardNo: '身份证号码',
		ownerCardFront: '身份证正面',
		ownerCardBack: '身份证反面',
		student: '学生',
		studentName: '学生姓名',
		openTime: '开店时间',
		zipcode: '邮政编码',
		status: '状态',
		startPay: '开启支付',
		account: '账号',
		orderSn: '订单号',
		orderStatus: '订单状态',
		orderTime: '下单时间',
		orderAmount: '订单总额',
		buyers: '买家',
		payType: '付款方式',
		payMode: '支付方式',
		payTime: '支付时间',
		freight: '运费',
		finishedTime: '完成时间',
		receiverName: '收货人姓名',
		fullAddress: '详细地址',
		shipmentNo: '发货单号',
		shipmentPlace: '发货地',
		consignor: '发货人',
		returnPlace: '退货地',
		statisticalTime: '统计时间',
		liveTitle: '直播标题',
		liveTime: '直播时间',
		goodsId: '商品ID',
		goodsName: '商品名称',
		goodsCate: '商品分类',
		cate: '分类',
		cate2: '类目',
		saleStatus: '出售状态',
		closeLive: '关播原因',
		liveType: '直播类型',
		bannedTime: '禁播时间',
		keyword: '关键词',
		tagName: '标签名称',
		marketTag: '活动标签',
		activityBg: '活动背景图',
		activityPrimary: '活动主题色',
		activityStatus: '活动状态',
		shopEnrollTime: '商家报名时间',
		goodsEnrollTime: '商品报名时间',
		preheatTime: '活动预热时间',
		salesTime: '活动售卖时间',
		activityIntro: '活动介绍',
		activityPrice: '活动价格',
		shopPrice: '店铺一口价',
		onlineStock: '线上库存',
		enrollSpecs: '报名规格',
		enrollStock: '报名数量',
		goodsMainpic: '宝贝主图',
		limitQuantity: '限购数量',
		creativeName: '创意名称',
		creativeId: '创意ID',
		creativeForm: '创意形式',
		promotionTitle: '推广标题',
		creativeStatus: '创意状态',
		creativeSource: '创意来源',
		creativeLifespan: '创意有效期',
		principalType: '主体类型',
		deliverySubject: '投放主体',
		creativeBaseInfo: '创意基本信息',
		pictureContainsBaby: '图片包含宝贝',
		teemoName: '落地页名称',
		teemoStatus: '落地页状态'
	},
	tableHead: {
		title: '标题',
		actions: '操作',
		status: '状态',
		studentName: '学生姓名',
		studentAccount: '学生|账号',
		shop: '店铺',
		shopkeeper: '店主(账号)',
		shopName: '店铺名称',
		class: '班级',
		belongClass: '所属班级',
		openTime: '开通时间',
		startPay: '是否开启支付',
		overallScore: '综合评分',
		rank: '排名',
		account: '账号',
		systemScore: '系统得分',
		teacherScore: '教师评分',
		adminScore: '管理员评分',
		total: '总计',
		totalPoints: '总分',
		totalScore: '总评分',
		details: '详情',
		intelligentScore: '智能评分',
		fraction: '分数',
		analyticScore: '分项评分',
		comment: '评语',
		orderSn: '订单号',
		orderTime: '下单时间',
		orderAmount: '订单总额',
		orderStatus: '订单状态',
		buyers: '买家',
		payMode: '支付方式',
		member: '会员',
		logins: '登录次数',
		lastLogin: '最后登录',
		liveCount: '直播场次',
		liveTimes: '直播时长(min)',
		dealOrder: '成交订单数',
		dealAmount: '成交金额',
		statisticalDate: '统计日期',
		liveTitle: '直播标题',
		liveTime: '直播时间',
		newFans: '新增关注数(粉丝)',
		goodsId: '商品ID',
		goodsName: '商品名称',
		goodsStatus: '商品状态',
		clickCount: '在线点击总数',
		orderCount: '成单数',
		price: '价格',
		describe: '描述',
		paymentReal: '真实交易',
		paymentStatus: '真实交易状态',
		classStatus: '班级状态',
		content: '内容',
		type: '类型',
		liveUsername: '直播账号',
		activityCate: '活动分类',
		activityName: '活动名称',
		activityPrice: '活动价',
		fixedPrice: '一口价',
		goodsEnrollStatus: '商品报名状态',
		pageBaseInfo: '页面基本信息',
		createTime: '创建时间',
		lastEditTime: '最后修改时间',
		impressions: '展现量',
		visitors: '访客人数',
		clickNum: '点击量',
		clickRate: '点击率'
	},
	actions: {
		add: '添加',
		edit: '编辑',
		view: '查看',
		delete: '删除',
		confirm1: '确定',
		confirm2: '确 定',
		cancel1: '取消',
		cancel2: '取 消',
		clear: '清除条件',
		submit: '提交',
		reset: '重置',
		systemScore: '智能评分',
		teacherScore: '教师评分',
		closeLive: '关播',
		reason: '原因',
		illegalDelisting: '违规下架',
		illegalRelieve: '违规解除',
		batchAddLiveIssue: '批量新增直播间问题/弹幕',
		newAdd: '新增',
		save: '保存',
		addTag: '新增标签',
		uploadImage: '上传图片',
		addActivity: '新增活动',
		tagManage: '标签管理',
		batchRestoreFunds: '批量还原资金账户',
		manualReview: '手动审核',
		rejectReject: '驳回拒绝',
		directlyThrough: '直接通过',
		auditReject: '审核拒绝',
		auditThrough: '审核通过',
		reject: '驳回',
		through: '通过'
	},
	option: {
		shopStatus: [{
				label: '待审核',
				value: '1'
			},
			{
				label: '审核通过',
				value: '2'
			},
			{
				label: '审核拒绝',
				value: '3'
			}
		],
		orderStatus: [{
				label: '待付款',
				value: '1'
			},
			{
				label: '待发货',
				value: '2'
			},
			{
				label: '已发货',
				value: '3'
			},
			{
				label: '已完成',
				value: '4'
			},
			{
				label: '已关闭',
				value: '5'
			}
		],
		payType: [
			// { label: '虚拟支付', value: '1' },
			{
				label: '在线支付',
				value: '2'
			}
		],
		memberKeyword: [
			// { label: '账号', value: '0' },
			{
				label: '学号',
				value: '1'
			},
			// { label: '电子邮箱', value: '2' },
			{
				label: '手机号码',
				value: '3'
			},
			{
				label: '真实姓名',
				value: '4'
			}
		],
		memberSort: [{
				label: '登录次数',
				value: '1'
			},
			{
				label: '最后登录',
				value: '2'
			}
		],
		saleStatus: [{
				label: '上架',
				value: '1'
			},
			{
				label: '下架',
				value: '0'
			},
			{
				label: '违规',
				value: '3'
			}
		],
		liveType: [{
				label: '直播中',
				value: '0'
			},
			{
				label: '强制下播',
				value: '1'
			}
		],
		liveIssueType: [{
				label: '问题',
				value: 1
			},
			{
				label: '弹幕',
				value: 2
			}
		],
		activityStatus: [{
				label: '状态',
				value: ''
			},
			{
				label: '未开始',
				value: 1
			},
			{
				label: '进行中',
				value: 2
			},
			{
				label: '已结束',
				value: 3
			}
		],
		goodsStatus: [{
				label: '出售中',
				value: 1
			},
			{
				label: '仓库中',
				value: 2
			},
			{
				label: '违规中',
				value: 3
			}
		],
		enrollStatus: [{
				label: '草稿',
				value: 0
			},
			{
				label: '审核通过',
				value: 1
			},
			{
				label: '审核拒绝',
				value: 2
			},
			{
				label: '预热中',
				value: 3
			},
			{
				label: '活动中',
				value: 4
			},
			{
				label: '活动结束',
				value: 5
			}
		],
		creativeForm: [
			{ label: '全部创意形式', value: '' },
			{ label: '竖版大图800x1200', value: 1 },
			{ label: '竖版大图513x750', value: 2 },
			{ label: '方图800x800', value: 3 },
			{ label: '方视频800x800', value: 4 },
			{ label: '竖版视频720x960', value: 5 },
			{ label: '竖版视频720x1280', value: 6 },
			{ label: '竖版视频750x1000', value: 7 },
			{ label: '竖版视频800x1200', value: 8 },
			{ label: '竖版视频1080x1440', value: 9 },
			{ label: '竖版视频1080x1920', value: 10 }
		],
		creativeStatus: [
			{ label: '全部创意状态', value: '' },
			{ label: '待审核', value: 0 },
			{ label: '审核通过', value: 1 },
			{ label: '审核拒绝', value: 2 },
			{ label: '创意过期', value: 3 },
			// { label: '创意故障', value: 4 },
		],
		creativeSource: [
			{ label: '全部创意来源', value: '' },
			{ label: '本地上传', value: 0 },
			{ label: '模板制作', value: 1 },
		],
		creativeSubject: [{
				label: '全部主体状态',
				value: ''
			},
			{
				label: '商品推广',
				value: 0
			},
			{
				label: '店铺推广',
				value: 1
			},
			{
				label: '自定义推广',
				value: 2
			}
		],
		teemoStatus: [{
				label: '落地页状态',
				value: ''
			},
			{
				label: '未发布',
				value: 0
			},
			{
				label: '待审核',
				value: 1
			},
			{
				label: '已发布',
				value: 2
			},
			{
				label: '被驳回',
				value: 3
			}
		]
	},
	validate: {
		required: '必填项不能为空!',
		region: '请选择省市区!',
		phone: '请填写正确的手机号码!',
		goodsEnrollTime: '商品报名截止时间不能小于商家报名截止时间!'
	},
	placeholder: {
		shopkeeper: '请输入店主',
		shopName: '请输入店铺名称',
		realName: '请输入姓名',
		studentName: '请输入学生姓名',
		fullAddress: '详细地址',
		account: '请输入账号',
		guidance: '可点击输入您的指导意见',
		startDate: '开始日期',
		endDate: '结束日期',
		orderSn: '请输入订单号',
		buyers: '请输入买家',
		keyword: '请输入关键词',
		sort: '排序',
		liveTitle: '请输入直播标题',
		goodsName: '请输入商品名称',
		goodsId: '多个ID,英文逗号隔开',
		className: '请输入班级名称',
		closeLive: '关播原因',
		datetime: '选择日期时间',
		liveIssueContent: '直播问题/弹幕内容',
		liveUsername: '多个“,”隔开',
		startTime: '开始时间',
		endTime: '结束时间',
		basicAmount: '请输入账户基础金额'
	},
	filters: {
		orderStatus: {
			0: '已取消',
			11: '待付款',
			20: '待发货',
			30: '待收货',
			40: '交易完成'
		},
		liveIssueType: {
			1: '问题',
			2: '弹幕'
		},
		activityStatus: {
			1: '未开始',
			2: '进行中',
			3: '已结束'
		},
		goodsStatus: {
			0: '仓库中',
			1: '出售中',
			2: '违规中'
		},
		enrollStatus: {
			0: '草稿',
			1: '审核通过',
			2: '审核拒绝',
			3: '预热中',
			4: '活动中',
			5: '活动结束'
		},
		creativeStatus: { 0: '未发布', 1: '待审核', 2: '已发布', 3: '被驳回' }
	},
	dialog: {
		add: '添加',
		edit: '编辑'
	},
	dialogTitle: {
		closeLive: '强制关播',
		addTag: '添加标签',
		editTag: '编辑标签',
		resetCateFunds: '重置类目资金',
		batchRestoreFunds: '批量还原资金账户'
	},
	confirm: {
		title: '提示',
		deleteText: '确认删除?',
		closeLive: '确认关播?',
		offShelf: '确认下架违规商品?',
		recoverShelves: '是否解除违规商品？',
		calculateSystem: '确定重新计算智能评分？',
		deleteTag: '确认删除标签？',
		deleteActivity: '确认删除活动？',
		restoreClassFunds: '是否确认重置并清空所选的{class}；班级所有会员账号账户中所含类目的所有资金和计划',
		restoreCateFunds: '确认是否重置类目资金',
		restoreMemberFunds: '是否确认重置并清空所选的{memner}；账号账户中所含类目的所有资金和计划',
		creativeReject: '确定将此创意驳回吗？',
		creativeThrough: '确定将此创意通过吗？',
		creativeAudit: '请选择审核修改该创意状态为',
		teemoReject: '确定将此落地页驳回吗？',
		teemoThrough: '确定将此落地页通过吗？',
		teemoAudit: '请手动审核该落地页'
	},
	successMsg: {
		add: '添加成功!',
		edit: '编辑成功!',
		delete: '删除成功!',
		update: '更新成功!',
		handle: '操作成功!',
		closeLive: '关播成功!'
	},
	errorMsg: {
		upload: '上传失败!',
		type: '请选择类型',
		content: '请输入内容',
		imageType: '上传图片只能是 JPG/JPEG/PNG 格式!',
		imageSize3M: '上传图片大小不能超过 3MB!',
		selectClass: '请选择班级',
		selectCate: '请选择类目',
		selectMember: '请选择会员'
	},
	cancelMsg: {
		delete: '已取消删除!',
		closeLive: '已取消关播!',
		handle: '已取消操作!'
	},
	breadcrumb: {
		shopManage: '店铺管理',
		orderManage: '订单管理',
		details: '详情',
		edit: '编辑',
		contentManege: '内容管理',
		marketTag: '活动标签'
	},
	tags: {
		open: '开启',
		close: '关闭',
		not: '无',
		member: '会员',
		shop: '店铺',
		live: '直播',
		order: '交易',
		goods: '商品',
		class: '班级',
		creative: '创意',
		teemo: '落地页'
	},
	pagination: {
		prevText: '上一页',
		nextText: '下一页'
	},
	unit: {
		to: '至',
		yuan: '元',
		piece: '件'
	}
}
export default teacher