const login = {
	studentTitle: '学生登录',
	teacherTitle: '教师登录',
	forget: '忘记密码',
	register: '免费注册',
	logIn: '登录',
	footer: {
		title: '酷特信息',
		copyright: '© 2019-现在 安徽酷特信息科技有限公司 版权所有',
		ICP: '工信部域名信息备案号：闵ICP备14008589号-1 | 增值电信业务经营许可证：闵B2-20160039 | 闽公网安备 35021102002111号'
	},
	validate: {
		usernameJson: { 1:'请输入学号', 2:'请输入账号' },
		password: '请输入密码',
		passwordMin: '密码长度最小6位',
	},
	placeholder: {
		usernameJson: { 1: '学号', 2: '账号' },
		password: '请输入登录密码'
	},
	confirm: {
		logoutTitle: '确认注销',
		logoutText: '您已注销，可以取消以停留在此页面，或再次登录'
	},
	actions: {
		relist: '重新登录',
		cancel: '取消'
	},
	messageTips: '提示',
	successMsg: '用户登录成功'
}

export default login