import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getStorage, getHost } from '@/utils/auth'
const domain = process.env.VUE_APP_BASE_URL  // 主域名
const host = getHost()  // 当前域名
const pathname = window.location.pathname
const goLogin = window.location.protocol + "//login." + domain

const rosterList = ['work.' + domain, 'liveplatform.' + domain, 'sycm.' + domain]

router.beforeEach(async (to, from, next) => {
	if (to.meta.title) {
		if(host.indexOf('sycm.' + domain) !== -1) {
			document.title = '生意参谋-零售电商大数据产品平台'
		} else{
			document.title = to.meta.title
		}
	}

	const hasToken = await store.dispatch("user/hasToken", to.query.token)
	
	if (host.indexOf('login.' + domain) !== -1) {
		// 如果是登录页不需跳转
		next()
	} else if (hasToken) {
		try {
			const hasStorage = getStorage()
			// 当用户信息不存在或cookie与本地存储token不一致时，重新获取用户信息
			const hasRoles = hasStorage === hasToken && store.getters.userInfo && store.getters.userInfo.userId > 0

			const userInfo = hasRoles ? store.getters.userInfo : await store.dispatch('user/getInfo')

			if (host.indexOf('work.' + domain) !== -1) {
				if (userInfo.open_shop === 0 && to.path !== '/home/open-shop-landing') {
					next(`/home/open-shop-landing`)
				} else if (userInfo.open_shop === 3 && to.path !== '/home/close-shop') {
					next(`/home/close-shop`)
				} else if ((userInfo.open_shop !== 0 && to.path === '/home/open-shop-landing')
				|| (userInfo.open_shop === 1 && !to.meta.menuAuth && userInfo.identity !== 2)
				|| (userInfo.open_shop === 1 && !to.meta.menuAuth && userInfo.identity === 2 && to.path.indexOf('/teacher/') === -1)
				|| (userInfo.open_shop !== 3 && to.path === '/home/close-shop')
				|| (userInfo.open_shop === 2 && userInfo.identity !== 2 && to.path.indexOf('/teacher/') !== -1)) {
					next(`/home/index`)
				} else {
					if(to.query.token) {
						next({path: '/redirect' + to.path})
					} else {
						next()
					}
				}
			} else {
				if(to.query.token) {
					next({path: '/redirect' + to.path})
				} else {
					next()
				}
			}
		} catch (error) {
			await store.dispatch('user/resetToken')
			Message.error(error || 'Has Error')
			window.location.href = goLogin
		}
	} else if (to.meta.requireAuth || rosterList.indexOf(host) > -1) {
		// 判断是否需要登录
		if (pathname.indexOf('/m/') === 0) {
			window.location.href = goLogin + '/m/?jump_url=' + window.location.href
		} else {
			window.location.href = goLogin + '?jump_url=' + window.location.href
		}
	} else {
		next()
	}
})
