// 移动商城

const mobileRouter = [{
		path: "/m/item/good/:id",
		name: "mGoodsInfo",
		component: () => import('@/views/mobile/goods/info'),
		meta: {
			requireAuth: false,
			title: "商品详情"
		}
	},{
		path: "/m/goods/comment/:id",
		name: "mGoodsComment",
		component: () => import('@/views/mobile/goods/comment'),
		meta: {
			requireAuth: false,
			title: "商品评价"
		}
	}, {
		path: "/m/cart/index",
		name: "mShoppingCart",
		component: () => import('@/views/mobile/cart/index'),
		meta: {
			requireAuth: true,
			title: "购物车",
			activeMenu: 'cart'
		}
	}, {
		path: "/m/user/index",
		name: "mUserCenter",
		component: () => import('@/views/mobile/user/index'),
		meta: {
			requireAuth: true,
			title: "我的",
			activeMenu: 'people'
		}
	}, {
		path: "/m/order/confirm",
		name: "mConfirmOrder",
		component: () => import('@/views/mobile/order/confirm'),
		meta: {
			requireAuth: true,
			title: "确认订单"
		}
	}, {
		path: "/m/order/address",
		name: "mOrderAddress",
		component: () => import('@/views/mobile/order/address'),
		meta: {
			requireAuth: true,
			title: "我的收货地址"
		}
	}, {
		path: "/m/user/address/list",
		name: "mAddressList",
		component: () => import('@/views/mobile/user/addressList'),
		meta: {
			requireAuth: true,
			title: "我的收货地址"
		}
	}, {
		path: "/m/user/address",
		name: "mAddressInfo",
		component: () => import('@/views/mobile/user/addressInfo'),
		meta: {
			requireAuth: true,
			title: "收货地址详情"
		}
	}, {
		path: "/m/order/msg/:id?",
		name: "mOrderMsg",
		component: () => import('@/views/mobile/order/result'),
		meta: {
			requireAuth: true,
			title: "订单详情"
		}
	}, {
		path: "/m/store/info/:id",
		name: "mStoreInfo",
		component: () => import('@/views/mobile/store/info'),
		meta: {
			requireAuth: false,
			title: "店铺详情"
		}
	}, {
		path: "/m/store/goods/:id",
		name: "mStoreGoods",
		component: () => import('@/views/mobile/store/goods'),
		meta: {
			requireAuth: false,
			title: "店铺宝贝"
		}
	}, {
		path: "/m/store/impression/:id",
		name: "mStoreImpression",
		component: () => import('@/views/mobile/store/impression'),
		meta: {
			requireAuth: false,
			title: "店铺印象"
		}
	}, {
		path: "/m/uulive/list-index",
		name: "mLiveListIndex",
		component: () => import('@/views/mobile/live/index'),
		meta: {
			requireAuth: false,
			title: "直播列表"
		}
	}, {
		path: "/m/uulive/info/:id",
		name: "mLiveInfo",
		component: () => import('@/views/mobile/live/info'),
		meta: {
			requireAuth: false,
			title: "直播详情"
		}
	}, {
		path: "/m/uulive/list-notice",
		name: "mLiveListNotice",
		component: () => import('@/views/mobile/live/index'),
		meta: {
			requireAuth: false,
			title: "预告列表"
		}
	}, {
		path: "/m/uulive/edit/:id",
		name: "mLiveEdit",
		component: () => import('@/views/mobile/live/edit'),
		meta: {
			requireAuth: false,
			title: "预告详情"
		}
	}, {
		path: "/m/uulive/list-playback",
		name: "mLiveListPlayback",
		component: () => import('@/views/mobile/live/index'),
		meta: {
			requireAuth: false,
			title: "回放列表"
		}
	}, {
		path: "/m/uulive/playback/:id",
		name: "mLivePlayback",
		component: () => import('@/views/mobile/live/playback'),
		meta: {
			requireAuth: false,
			title: "回放详情"
		}
	}, {
		path: "/m/uulive/goods/:id",
		name: "mLiveGoods",
		component: () => import('@/views/mobile/live/goods'),
		meta: {
			requireAuth: false,
			title: "直播宝贝"
		}
	}, {
		path: "/m/order/list/:type?",
		name: "mOrderList",
		component: () => import('@/views/mobile/order/list'),
		meta: {
			requireAuth: true,
			title: "订单列表"
		}
	}, {
		path: "/m/order/info/:id",
		name: "mOrderInfo",
		component: () => import('@/views/mobile/order/info'),
		meta: {
			requireAuth: true,
			title: "订单详情"
		}
	}, {
		path: "/m/order/logistic/:id/:code?",
		name: "mOrderLogistic",
		component: () => import('@/views/mobile/order/logistic'),
		meta: {
			requireAuth: true,
			title: "物流详情"
		}
	}, {
		path: "/m/order/refund/:order_id/:goods_id?",
		name: "mOrderRefund",
		component: () => import('@/views/mobile/order/refundType'),
		meta: {
			requireAuth: true,
			title: "选择售后类型"
		}
	}, {
		path: "/m/refund/apply/:order_id/:goods_id?",
		name: "mRefundApply",
		component: () => import('@/views/mobile/order/refundApply'),
		meta: {
			requireAuth: true,
			title: "申请退款"
		}
	}, {
		path: "/m/refunds/apply/:order_id/:goods_id?",
		name: "mRefundsApply",
		component: () => import('@/views/mobile/order/refundsApply'),
		meta: {
			requireAuth: true,
			title: "申请退货退款"
		}
	}, {
		path: "/m/refund/list",
		name: "mRefundList",
		component: () => import('@/views/mobile/order/refundList'),
		meta: {
			requireAuth: true,
			title: "退款/售后"
		}
	}, {
		path: "/m/refund/info/:id",
		name: "mRefundInfo",
		component: () => import('@/views/mobile/order/refundInfo'),
		meta: {
			requireAuth: true,
			title: "退款详情"
		}
	}, {
		path: "/m/refund/logistics/:id",
		name: "mRefundLogistics",
		component: () => import('@/views/mobile/order/refundLogistics'),
		meta: {
			requireAuth: true,
			title: "填写退货物流"
		}
	}, {
		path: "/m/refund/history/:id",
		name: "mRefundHistory",
		component: () => import('@/views/mobile/order/refundHistory'),
		meta: {
			requireAuth: true,
			title: "协商历史"
		}
	}, {
		path: "/m/order/evaluate/:id",
		name: "mOrderEvaluate",
		component: () => import('@/views/mobile/order/evaluate'),
		meta: {
			requireAuth: true,
			title: "发表评价"
		}
	}, {
		path: "/m/evaluate/msg/:id?",
		name: "mEvaluateMsg",
		component: () => import('@/views/mobile/order/evaluateMsg'),
		meta: {
			requireAuth: true,
			title: "我的评价"
		}
	}, {
		path: "/m/evaluate/list/:type?",
		name: "mEvaluateList",
		component: () => import('@/views/mobile/order/evaluateList'),
		meta: {
			requireAuth: true,
			title: "我的评价"
		}
	}, {
		path: "/m/item/about",
		name: "mAbout",
		component: () => import('@/views/mobile/public/about'),
		meta: {
			requireAuth: false,
			title: "软件许可使用协议"
		}
	}, {
		path: "/m/item/policy",
		name: "mPolicy",
		component: () => import('@/views/mobile/public/policy'),
		meta: {
			requireAuth: false,
			title: "酷特信息隐私政策"
		}
	}, {
        path: "/m/collocation/:id",
        name: "mGoodsCollocation",
        component: () => import('@/views/mobile/goods/collocation'),
        meta: {
            title: "商品详情"
        }
    }, {
		path: '/redirect/:path(.*)',
		component: () => import('@/views/mobile/login/auth-redirect')
	}, {
		path: "/m/404",
		component: () => import('@/views/404.vue'),
		meta: {
			requireAuth: false,
			title: "404"
		}
	}, {
		path: '*',
		redirect: '/m/404'
	}
]

export default mobileRouter
